import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderContent from '../components/HeaderContent';
import FooterContent from '../components/FooterContent';
import ImageCard from '../components/ImageCard';
import checkLS from '../utilities/localStorage';

function HomePage() {

    const [data, setData] = useState([]);
	const homepageAID = 2;
	let apiURL = '/api/graphql/'
	if (window.location.host === 'local.ugworks.com' || window.location.host === 'localhost:3000') {
		apiURL = 'http://localhost:3500/api/graphql'
	}

	// Fetch data from backend API
	useEffect(() => {
		const request = {
			operationName: 'getCards',
			query: `query getCards($aid: Int!) {
						getCards(aid: $aid) {
							pid
							filename
							filepath
							title
							caption
							user1
						}
					}`,
			variables: {
				aid: homepageAID
			}
		}

		if (!checkLS(homepageAID)) {
			axios
				.post(apiURL, request) // Backend endpoint
				.then(response => {
					console.log('GraphQL response -',response)
					setData(response.data.data.getCards); // Save data to state
					localStorage.setItem(homepageAID, JSON.stringify(response.data.data.getCards));
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		} else {
			setData(JSON.parse(localStorage.getItem(homepageAID)))
		}
	}, [homepageAID, apiURL]);

    return (
		<>
			<HeaderContent />
			<div id="content" className='container'>
				<h1>Recent Works</h1>
				<div id="gallery" className='row gx-5'>
					{ data.length > 0 ? (
						<>
							{data.slice(0, 8).map((row, index) => (
								<ImageCard data={row} key={index} />
							))}
						</>
					) : (
						<p>No data found</p>
					)}
				</div>
			</div>
			<FooterContent />
		</>
    )
}

export default HomePage;