import React from 'react';
import jQuery from 'jquery';
import lightbox from 'lightbox2';

function ImageCard(props) {

    const img = props.data 

    return (
        <div className="col">
            <a href={`//gallery.ugworks.com/albums/${img.filepath}${img.filename}`} title={img.title} data-lightbox={img.filename} data-title={img.title}><img className="shadow" src={`//gallery.ugworks.com/albums/${img.filepath}thumb_${img.filename}`} alt={img.title} /></a>
            <h2><a href={img.user1} target="_blank" rel="noreferrer" title={`Click to go to ${img.title}'s website.`} dangerouslySetInnerHTML={{ __html: img.title }} ></a></h2>
            <p dangerouslySetInnerHTML={{ __html: img.caption }} ></p>
        </div>
    )

}

export default ImageCard;