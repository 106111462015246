import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo from '../images/ugworks_logo.png';

function HeaderContent() {

	const canonicalUrl = window.location.href

	const topnav = [
		{ 'title': 'home', 'url': '/' },
		{ 'title': 'about', 'url': '/about' },
		{ 'title': 'services', 'url': '/services' },
		{ 'title': 'portfolio', 'url': '/portfolio' },
		{ 'title': 'contact', 'url': '/contact' }
	]

	const active = (val) => {
		const url = window.location.pathname;
		if (url.indexOf(val) > -1) {
			return 'current'
		} else if (url === '/' && val === 'home') {
			return 'current'
		}
	}

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
			</Helmet>
			<div className='container-fluid' id="header">
				<ul id='topnav'>
					{topnav.map((item, index) => (
						<li key={index}>
							<Link to={item.url} className={active(item.title)} >{item.title}</Link>
						</li>
					))}
				</ul>
				<div id="logo">
					<a href="/"><img src={logo} alt="logo" width="692" height="139" /></a>
				</div>
				<h2>We specialize in small businesses that need a beautiful,<br />yet functional web presence to help their business grow!</h2>
			</div>
		</HelmetProvider>
	)

}

export default HeaderContent;