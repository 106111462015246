import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderContent from '../components/HeaderContent';
import FooterContent from '../components/FooterContent';
import { Link, useParams } from 'react-router-dom';
import ImageCard from '../components/ImageCard';
import checkLS from '../utilities/localStorage';

function PortfolioPage() {

	const pages = [
		{ 'page': 'development', 'aid': 2, 'title': 'Web Development'},
		{ 'page': 'archived', 'aid': 9, 'title': 'Archive'},
		{ 'page': 'cgi', 'aid': 3, 'title': '3D'},
		{ 'page': 'print-design', 'aid': 1, 'title': 'Print Design'},
	]

	const { page } = useParams();
	let onPageLoad = pages[0]

	console.log(page)

	if (page) {
		onPageLoad = pages.find(item => item.page === page)
	}

	const [data, setData] = useState([]);
	const [aid, setAid] = useState(onPageLoad.aid)
	let apiURL = '/api/graphql/'
	if (window.location.host === 'local.ugworks.com' || window.location.host === 'localhost:3000') {
		apiURL = 'http://localhost:3500/api/graphql'
	}

	const clickSetAid = (val) => {
		setAid(val)
	}

	// Fetch data from backend API
	useEffect(() => {
		const request = {
			operationName: 'getCards',
			query: `query getCards($aid: Int!) {
						getCards(aid: $aid) {
							pid
							filename
							filepath
							title
							caption
							user1
						}
					}`,
			variables: {
				aid: aid
			}
		}

		if (!checkLS(aid)) {
			axios
				.post(apiURL, request) // Backend endpoint
				.then(response => {
					console.log('GraphQL response -',response)
					setData(response.data.data.getCards); // Save data to state
					localStorage.setItem(aid, JSON.stringify(response.data.data.getCards));
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		} else {
			setData(JSON.parse(localStorage.getItem(aid)))
		}
	}, [aid, apiURL]);

	return (
		<>
			<div id="wrapper">
				<HeaderContent />
					<div id="content" className='container'>
						<h1>Portfolio Page</h1>
						<div id="categories">
							Sections:
							{pages.map((row, index) => (
								<div key={index}>
									<Link to={row.page} className={row.aid === aid ? 'active' : 'non'} onClick={() => clickSetAid(row.aid)} >{row.title}</Link>
								</div>
							))}
						</div>
						<div id="gallery" className='row gx-5'>
							{data.length > 0 ? (
								<>
									{data.map((row, index) => (
										<ImageCard data={row} key={index} />
									))}
								</>
							) : (
								<p>No data found</p>
							)}
						</div>
					</div>
			</div>
			<FooterContent />
		</>
	)

}

export default PortfolioPage;