export default function checkLS(key) {
    // Check if the key exists in localStorage
    if (!localStorage.getItem(key) || window.location.host === 'local.ugworks.com') {
        // If the key does not exist, return false
        console.log('No, localStorage does not exist or local testing')
        return false
    } else {
        // If the key exists, return true
        console.log('Yes, localStorage exists')
        return true
    }
}